import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import JsonExcel from "vue-json-excel"
import axios from "axios"
import moment from "moment"

Vue.component("downloadExcel", JsonExcel)

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$moment = moment

window.addEventListener('beforeunload', () => {
  localStorage.setItem('pageRefresh', 'true');
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
