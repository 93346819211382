import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import User from '../src/services/User.js'

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    // login
    {
      name: "Login",
      path: "/login",
      component: () => import("@/views/dashboard/pages/LoginPage"),
      // meta: { guest: true },
    },
    //Dashboard global
    {
      path: "/",
      // component: () => import("@/views/dashboard/pages/LoginPage"),
      component: () => import("@/views/dashboard/Index"),
      meta: { requiresAuth: true },
      children: [  
        // Dashboard
        {
          name: "Tableau de bord",
          path: "",
          component: () => import("@/views/dashboard/Dashboard"),
          meta: { requiresAdmin: true },
        },
        // Réglages
        {
          name: "Réglages",
          path: "pages/settings",
          component: () => import("@/views/dashboard/pages/Settings"),
          meta: { requiresAdmin: true },
        },
        // Membres
        {
          name: "Adhérents",
          path: "pages/members",
          component: () => import("@/views/dashboard/pages/Members"),
          meta: { requiresAdmin: true },
        },
        // Profile
        {
          name: "Emargement adhérents",
          path: "pages/emargement",
          component: () => import("@/views/dashboard/pages/EmargementPage"),
          meta: { requiresAdmin: false },
        },
        // Profile
        {
          name: "Profil utilisateur",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile"),
          meta: { requiresAdmin: false },
        },
        
        // {
        //   name: "Notifications",
        //   path: "components/notifications",
        //   component: () => import("@/views/dashboard/component/Notifications"),
        // },
        // {
        //   name: "Icons",
        //   path: "components/icons",
        //   component: () => import("@/views/dashboard/component/Icons"),
        // },
        // {
        //   name: "Typography",
        //   path: "components/typography",
        //   component: () => import("@/views/dashboard/component/Typography"),
        // },
        // // Tables
        // {
        //   name: "Regular Tables",
        //   path: "tables/regular-tables",
        //   component: () => import("@/views/dashboard/tables/RegularTables"),
        // },
        
        // // Maps
        // {
        //   name: "Google Maps",
        //   path: "maps/google-maps",
        //   component: () => import("@/views/dashboard/maps/GoogleMaps"),
        // },

        // // Upgrade
        // {
        //   name: "Upgrade",
        //   path: "upgrade",
        //   component: () => import("@/views/dashboard/Upgrade"),
        // },
      ],
    },
    // { path: '*', redirect: '/login' }
  ],
});

router.beforeEach(async(to, from, next) => {
  const pageRefresh = localStorage.getItem('pageRefresh');

    if (pageRefresh && to.name !== "Login") {
        console.log('Page was refreshed');
        localStorage.removeItem('pageRefresh');

        // Operation refresh
        if(localStorage.getItem('refreshToken') && localStorage.getItem('refreshToken')!=""){
          const userGet = await User.getCheckToken({token:localStorage.getItem('refreshToken')})
         console.log("userGet",userGet)
          if (userGet && userGet!=null) {
            // localStorage.setItem("token", userGet.token);
            // localStorage.setItem("refreshToken", userGet.refreshToken);
            store.state.user = true
            store.state.currentUser = userGet
          }
          }
          //Operation refresh
    }
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.state.user) {
          next('/login');
      } else {
          if (to.matched.some(record => record.meta.requiresAdmin)) {
              const userRoles = store.state.currentUser.user.autorisation;
              const requiredRoles = 1;
              if (requiredRoles >= userRoles) {
                  next();
              } else {
                  next('/pages/user'); // Rediriger vers une page d'accès refusé ou dashboard
              }
          } else {
              next();
          }
      }
  } else {
      next();
  }
});


export default router;